import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet'

const PrivacyPolicyPage = ( {data} ) => {
    const title = data.prismicPrivacyPolicy.data.title.text
    const policy = data.prismicPrivacyPolicy.data.privacy_policy.html

    return (
        
      <Layout>

        <Helmet>
          <title>Privacy Policy</title>
          <meta name='description' content='Privacy Policy for Monarch People' />
        </Helmet>
        
        <div className="page-content rich-text-page privacy-policy">
          <title>{title}</title>
          <h1>{title}</h1>
          
          <div className="privacy-policy-content">{parse(policy)}</div>
        </div>
        
      </Layout>
    )
  }

export default PrivacyPolicyPage

export const query = graphql`
  query {
    prismicPrivacyPolicy {
      data {
        title {
          text
        }
        privacy_policy {
          html
        }
      }
    }
  }
`